import { computed, unref } from 'vue'
import addBeneficiary from '@/store/cart/cartWorkflows/common/module/addBeneficiary'
import basket from '@/store/cart/cartWorkflows/common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import apiV4 from '@/services/apiV4'
import { DataStoreKey } from '@/services/dataStore/types'
import settings from '@/store/cart/cartWorkflows/common/module/settings/Tr'
import importOrAdd from '@/store/cart/cartWorkflows/common/module/importOrAdd'
import { moduleConfig as clearCartPopupConfig } from '@/components/popins/ClearCart/workflow'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,
  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const useWorkflow = useStep.useWorkflow()

        useWorkflow.table.value.columns = [
          {
            id: 'beneficiary',
            label: 'table.headers.beneficiary',
            sort: 'beneficiary.name',
          },
          { id: 'quantity', label: 'table.headers.quantity' },
          { id: 'amount', label: 'table.headers.amount', sort: 'totalAmount' },
          { id: 'delivery', label: 'table.headers.delivery' },
        ]

        if (cart.remote.imports.length === 0) {
          useWorkflow.table.value.columns.push({ id: 'more', label: '' })
        } else {
          useWorkflow.table.value.readonly = true
        }
        useWorkflow.table.value.selectable = false
        useWorkflow.table.value.loading = false
        useWorkflow.table.value.rowComponent = () => import('@/components/Cart/Step/Basket/Row/CtrRow.vue')

        const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const dataStoreDeliveryPoint = dataStoreDeliveries ? dataStoreDeliveries[cart.meta.productCode] : null

        let addActionId = addBeneficiary.config.id
        let addActionType = ActionType.Drawer

        if (typeof record.totalItems === 'number' && record.totalItems > 0 && cart.remote.imports.length > 0) {
          addActionId = clearCartPopupConfig.id
          addActionType = ActionType.Popin
        }

        return {
          ...useWorkflow,
          hasBeneficiaryFilter: cart.meta.isNominative,
          emptyDescription: 'cart.tr.emptyDescription',
          export: {
            id: 'btn-export-cart',
            filename: 'Restitution.csv',
            fetchData: async () => {
              if (
                useWorkflow.response.data.value?.totalElements == null ||
                useWorkflow.response.data.value?.totalElements === 0
              ) {
                return []
              }

              const result = await apiV4.carts.fetchItems(cart.remote.id, {
                page: 1,
                pageSize: useWorkflow.response.data.value.totalElements,
                isNewBeneficiary: useWorkflow.beneficiaryOption.value?.value as boolean,
              })

              if (result.isErr) {
                return []
              }

              return (
                result.value.items?.map((item) => ({
                  ['table.headers.matricule']: item.beneficiary?.registrationNumber ?? null,
                  ['table.headers.lastname']: item.beneficiary?.name ?? null,
                  ['table.headers.firstname']: item.beneficiary?.firstName ?? null,
                  ['table.headers.quantity']: item.totalQuantities,
                  ['table.headers.valueOfTitle']: item.totalTitleAmount,
                  ['table.headers.delivery']: item.deliveryPointReference,
                  ['table.headers.distribution']: item.distributionPointReference,
                })) ?? []
              )
            },
          },
          addAction: computed(() =>
            useAction({
              id: addActionId,
              name: 'cart.button.addBenef',
              type: addActionType,
              icon: unref(useWorkflow.addAction)?.icon,
              refresh: false,
              payload: {
                key: 'addBeneficiary',
                nbItems: useWorkflow.response.data.value?.totalElements ?? 0,
              },
            }),
          ),
          showSettings: dataStoreValueOfTitle === null && (cart.meta.isPlasticless || dataStoreDeliveryPoint === null),
          settingsAction: useAction({
            name: 'settings',
            id: settings.config.id,
            type: ActionType.Drawer,
            refresh: false,
            payload: {
              showDelivery: true,
              showValueOfTitle: true,
              form: 'all',
              close: false,
            },
          }),
          actionsAction: useAction({
            name: 'import-or-add',
            id: importOrAdd.config.id,
            type: ActionType.Popin,
            refresh: false,
          }),
        }
      },
    }
  },
}

export default step
