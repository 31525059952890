import Router, { type Route, type NavigationGuard } from 'vue-router'
import { router as AuthRouter } from './_subs/Auth/index'
import { router as CartRouter } from './_subs/Cart/index'
import { router as SimulatorRouter } from './_subs/Simulator/index'
import { router as SettingsRouter } from './_subs/Settings/index'
import { router as ManagersRouter } from './_subs/Managers/index'
import { router as MyProfileRouter } from './_subs/MyProfile/index'
import { router as CommandeRouter } from './_subs/Commande/index'
import { router as InvoicesRouter } from './_subs/Invoices/index'
import { router as MigrationRouter } from './_subs/Migration'
import { router as BenefitsCalculator } from './_subs/BenefitsCalculator'
import DashboardRouter from './_subs/Dashboard'
import { loadV1, redirectV1 } from './_subs/v1'
import { useStore as useTrackingStore } from '@/store/tracking'
import pages from '@/assets/constantes/pages.json'
import { accessor } from '@/store'
import ABILITIES from '@/variables/habilitations.json'
import { MODE_MAINTENANCE } from '@/parameters'
import { FeatureFlag } from '@/store/featureFlag/model'
import { useStore as useFeatureFlagStore } from '@/store/featureFlag'
import { useStore as useDrawerStore } from '@/store/drawer'
import { useModalStore } from '@/store/modal'

const Faqintosh = () => import('@/views/Admin/Faq/Faq.vue')

const ChildView = () => import('@/views/DefaultRouter/Child/index.vue')
const DefaultRoot = () => import('@/views/DefaultRouter/Root/index.vue')

const router = new Router({
  mode: 'history',
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
  routes: [
    AuthRouter,
    CartRouter,
    MigrationRouter,
    SimulatorRouter,
    ManagersRouter,
    BenefitsCalculator,
    {
      path: pages.ACCUEIL.path,
      meta: {
        title: pages.ACCUEIL.title,
      },
      name: pages.ACCUEIL.name,
      component: () => import('@/views/HomePage.vue'),
    },
    DashboardRouter,
    MyProfileRouter,
    {
      path: '/aide/FAQ',
      meta: {
        refAbility: [ABILITIES.HELP.FAQ],
      },
      component: DefaultRoot,
      children: [
        {
          path: '',
          redirect: '/aide/FAQ/Espace%20Client',
          meta: {
            title: 'FAQ',
          },
          name: 'FAQView',
          component: Faqintosh,
        },
        {
          path: ':categ',
          meta: {
            title: 'FAQ',
            breadcrumb: 'FAQ',
          },
          component: ChildView,
          children: [
            {
              path: '',
              meta: {
                title: 'FAQ',
              },
              name: 'FAQViewCateg',
              component: Faqintosh,
            },
            {
              path: ':topic',
              meta: {
                title: 'FAQ',
                breadcrumb: ({ route }: { route: Route }) => {
                  return route.params.topic
                },
              },
              component: ChildView,
              children: [
                {
                  path: '',
                  name: 'FAQViewTopic',
                  component: Faqintosh,
                  meta: {
                    title: 'FAQ',
                  },
                },
                {
                  path: ':ref',
                  meta: {
                    title: 'FAQ',
                  },
                  name: 'FAQViewRef',
                  component: Faqintosh,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: pages.COMMANDER.path,
      meta: {
        title: pages.COMMANDER.title,
        breadcrumbLink: '/accueil',
      },
      component: DefaultRoot,
      props: {
        title: 'Commander',
        faqPhrase: "Besoin d'aide pour passer Commande ?",
      },
    },
    CommandeRouter,
    SettingsRouter,
    {
      path: '/commande/kadeos',
      redirect: 'commande/kadeos',
    },
    {
      path: '/commandes-et-factures',
      meta: {
        breadcrumb: 'Commandes et factures',
        breadcrumbLink: '/accueil',
      },
      component: DefaultRoot,
      props: {
        faqPhrase: 'Une question sur le suivi de votre commande ?',
      },
      children: [
        {
          path: 'suivi-commande',
          meta: {
            breadcrumb: 'Historique des commandes',
            title: 'Suivi de commandes',
            howTo: 'suivi-commande-comment-faire',
          },
          component: ChildView,
          children: [
            {
              path: '',
              meta: {
                title: 'Suivi de commandes',
              },
              name: 'OrdersBoard',
              component: () => import('@/views/Admin/OrderHistory/OrdersBoard.vue'),
            },
            {
              path: ':orderNumber/:orderId?',
              meta: {
                title: 'Suivi de commandes',
                refAbility: [ABILITIES.COMMANDE.SUIVI_CMD],
                breadcrumb: ({ route }: { route: Route }) => {
                  return `Commande : N°${route.params.orderNumber}`
                },
              },
              name: 'OrderDetail',
              component: () => {
                const store = useFeatureFlagStore()

                if (store.isEnabled(FeatureFlag.NewOrderDetail)) {
                  return import('@/views/Admin/Orders/Details.vue')
                } else {
                  return import('@/views/Admin/OrderHistory/OrderDetail.vue')
                }
              },
            },
          ],
        },
        InvoicesRouter,
      ],
    },
    {
      path: '/aide/contact',
      name: 'contact',
      component: () => import('@/views/Admin/Contact.vue'),
      meta: {
        title: 'Mes demandes',
        refAbility: [ABILITIES.HELP.CONTACT],
        howTo: 'contact-comment-faire',
        breadcrumb: ({ route }: { route: Route }) => {
          return route.query?.open ? 'Nous contacter' : 'Suivre mes demandes'
        },
      },
    },
    {
      path: '/commande/suivi-commande',
      redirect: '/commandes-et-factures/suivi-commande',
    },
    {
      path: '/facturation/factures-avoirs',
      redirect: '/commandes-et-factures/factures-avoirs',
    },
    {
      path: '/facturation/moyens-paiements',
      redirect: '/parametres/moyens-paiements',
    },
    {
      path: '/accueil/',
      redirect: '/accueil',
    },
    {
      path: '/',
      redirect: '/accueil',
    },
    loadV1,
    redirectV1,
    {
      path: '*',
      name: 'Not Found',
      meta: {
        title: 'Page introuvable',
        breadcrumb: 'Page introuvable',
      },
      component: () => import('@/views/Pages/404.vue'),
    },
  ],
})

const ifNotAuthenticated: NavigationGuard = (_, __, next) => {
  if (!accessor.auth.isLoggedIn) {
    next()
    return
  }

  next('/')
}

const ifAuthenticated: NavigationGuard = (to, from, next) => {
  if (!accessor.auth.isLoggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
    return
  } else if (to.meta?.refAbility && !accessor.session.hasPermission(to.meta?.refAbility)) {
    next('/')
    return
  }

  next()
}

const fetchRouter: NavigationGuard = async (to, from) => {
  if (from === Router.START_LOCATION) {
    const { access_token } = to.query
    if (typeof access_token === 'string') {
      accessor.auth.setToken({
        accessToken: access_token,
      })
    }

    accessor.session.setLoading(true)

    const result = await accessor.session.fetchSession()
    if (result.isErr) {
      await accessor.auth.logout({ redirect: false })
    }

    const trackingStore = useTrackingStore()
    trackingStore.setMetaData()

    accessor.session.setLoading(false)
  }
}

router.beforeEach(async (to, from, next) => {
  await fetchRouter(to, from, next)

  if (to.path !== '/login/maintenance' && MODE_MAINTENANCE === 'true') {
    await accessor.auth.logout({ redirect: false })
    next('/login/maintenance')
    return
  }

  const drawerStore = useDrawerStore()

  if (drawerStore.pages.length > 0) {
    if (drawerStore.pages.find((page) => page.props?.record?.close === false)) {
      // Forbid navigation if there's a drawer open that cannot be closed
      return false
    } else {
      // Close all open drawers when navigating
      drawerStore.popAll()
    }
  }

  const modalStore = useModalStore()
  modalStore.popAll()

  if (to.meta?.title) {
    document.title = to.meta.title
  }

  if (to.matched.some((record) => record.meta.clearSession)) {
    await accessor.auth.logout({ redirect: false })
  }

  if (to.matched.some((record) => record.meta.isPublicUrl)) {
    next()
  } else if (to.matched.some((record) => record.meta.isAuthUrl)) {
    ifNotAuthenticated(to, from, next)
  } else {
    ifAuthenticated(to, from, next)
  }
})

router.afterEach(() => {
  if (window.KAMPYLE_ONSITE_SDK && typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function') {
    window.KAMPYLE_ONSITE_SDK.updatePageView()
  }
})

export default router
