import { Result } from '@badrap/result'
import confirmation from '@/store/cart/cartWorkflows/common/steps/confirmation'
import type { ConfirmationWorkflow } from '@/components/Cart/Step/Confirmation/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { useMigrationStore } from '@/store/migration'
import type { CFOrderConfirmationCard } from '@/services/contentful/types/CFOrderConfirmationCard'

const step: StepBuilder<ConfirmationWorkflow> = {
  ...confirmation,
  async prepareStep(cart) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: Record<string, any> = {}

    if (confirmation.prepareStep) {
      const result = await confirmation.prepareStep(cart)

      if (result.isErr) {
        return Result.err(result.error)
      }

      data = result.value
    }

    const migrationStore = useMigrationStore()
    const migrationCard = await migrationStore.fetchOrderConfirmationCard()

    if (migrationCard) {
      return Result.ok({ cfCard: migrationCard, ...data })
    }

    return Result.ok({ ...data })
  },
  useStep(cart, workflow, record: { cfCard?: CFOrderConfirmationCard }, useAction) {
    const useStep = confirmation.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const useWorkflow = useStep.useWorkflow()
        let component = useWorkflow.component

        if (record.cfCard) {
          component = () => import('@/components/Cart/Step/Confirmation/subs/Contentful.vue')
        }

        return {
          ...useWorkflow,
          component,
          cfCard: record.cfCard,
        }
      },
    }
  },
}

export default step
