import { Result } from '@badrap/result'
import { computed, reactive } from 'vue'
import { helpers, required, between, requiredIf, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {
  type AddItemWorkflow,
  type AmountData,
  moduleConfig,
  type AddItemForm,
  type MessageConfigs,
} from '@/components/Cart/Module/AddItem/workflow'
import { CartItemType, type CartItem, type CartItemRequest } from '@/services/carts/types'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import apiV4 from '@/services/apiV4'
import { useI18n } from '@/composables/useI18n'
import { toPrice } from '@/utils/price'
import { useStore as useCartStore } from '@/store/cart/store'
import { getErrorMessage } from '@/utils/vuelidate'
import { ArticleCode } from '@/variables/ArticleCode'
import { accessor } from '@/store'
import type { CFCartItemConfig } from '@/services/contentful/types/CFProduct'
import { useStore as useTrackingStore } from '@/store/tracking'
import { useCKSProductItemTracking } from '@/composables/useCartTracking'

const addCKSProduct: ModuleBuilder<AddItemWorkflow> = {
  config: moduleConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow: (record?: { item?: CartItem; itemConfigs?: CFCartItemConfig[] }) => {
        const i18n = useI18n()
        const cartStore = useCartStore()
        const article = cartStore.article(cart.meta.productCode, cart.meta.articleCode)
        const config = cartStore
          .cartItemConfigs(cart.meta.productCode, cart.meta.articleCode)
          ?.find((config) => config.type === CartItemType.Card && !config.nominative)
        if (config == null) {
          throw new Error('cart.errors.cartItemConfig.notFound')
        }

        const messageConfigs: MessageConfigs = {
          line1: {
            show: true,
            maxChar: 20,
          },
          line2: {
            show: true,
            maxChar: 26,
          },
          line3: {
            show: false,
            maxChar: 0,
          },
        }
        const noSpecialChars = helpers.withMessage(
          i18n.t('cart.modules.addItem.personalizedMessage.errors.noSpecialChars').toString(),
          helpers.regex(/^[^,;:«»()_‘.&{}°/\\*$]+$/),
        )

        const showEvent = cart.meta.articleCode !== ArticleCode.CARTE_KADEOS_CULTURE
        const showDeliveryPoint = !cart.meta.isCrossSell
        const events = cartStore.eventsByProductCode(cart.meta.productCode)

        const form: AddItemForm = reactive({
          itemType: config.type as CartItemType,
          event: showEvent ? (events.length > 0 ? events[0] : null) : null,
          quantity: 10,
          amount: {
            value: config.recommendedAmount,
            decompositions: [],
            hasDecomposition: false,
          },
          deliveryPoint: null,
          message: { line1: null, line2: null, line3: null },
          pocketsQuantity: 0,
        })

        if (record?.item != null) {
          form.event = events.find((event) => event.value === record?.item?.eventCode) ?? form.event
          form.quantity = record.item.packagingQuantity ?? form.quantity
          form.deliveryPoint = record.item.deliveryPointReference
            ? {
                reference: record.item.deliveryPointReference,
                hasDistribution: !!record.item.distributionPointReference,
                distributionRef: record.item.distributionPointReference ?? undefined,
              }
            : null
          form.message = record.item.message ?? form.message
          form.amount = {
            value: record.item.compositions.reduce<number>((r, composition) => {
              r += composition.titleValue
              return r
            }, 0),
            decompositions: record.item.compositions.map((composition) => ({
              value: composition.titleValue,
              count: composition.quantity,
            })),
            hasDecomposition: true,
          }
        }

        const rules = computed(() => ({
          itemType: { required },
          event: {
            required: helpers.withMessage(
              i18n.t('cart.modules.addItem.events.errors.required').toString(),
              requiredIf(showEvent),
            ),
          },
          quantity: {
            required: helpers.withMessage(i18n.t('cart.modules.addItem.quantity.errors.required').toString(), required),
            between: helpers.withMessage(({ $params }) => {
              return i18n
                .t(`cart.modules.addItem.quantity.errors.${config.type}Between`, {
                  min: $params.min,
                  max: $params.max,
                })
                .toString()
            }, between(config.minQuantity, config.maxQuantity)),
          },
          amount: {
            required: helpers.withMessage(i18n.t('cart.modules.addItem.amount.errors.required').toString(), required),
            integer: helpers.withMessage(
              i18n.t('cart.modules.addItem.amount.errors.integer').toString(),
              (value: AmountData) => Number.isInteger(value.value / 100),
            ),
            between: helpers.withMessage(
              () =>
                i18n
                  .t('cart.modules.addItem.amount.errors.between', {
                    min: toPrice(config.minAmount),
                    max: toPrice(config.maxAmount),
                  })
                  .toString(),
              (amount: AmountData) => amount.value >= config.minAmount && amount.value <= config.maxAmount,
            ),
          },
          deliveryPoint: {
            reference: {
              required: helpers.withMessage(
                i18n.t('cart.modules.addItem.deliveryPoint.errors.deliveryPoint.required').toString(),
                requiredIf(showDeliveryPoint),
              ),
            },
            distributionRef: {
              required: helpers.withMessage(
                i18n.t('cart.modules.addItem.deliveryPoint.errors.distributionPoint.required').toString(),
                requiredIf(
                  showDeliveryPoint &&
                    (accessor.session.clientProducts[cart.meta.productCode]?.orderSettings.distributionPointMandatory ??
                      false),
                ),
              ),
            },
          },
          message: {
            line1: {
              maxLength: helpers.withMessage(
                i18n
                  .t('cart.modules.addItem.personalizedMessage.errors.maxLength', { max: messageConfigs.line1.maxChar })
                  .toString(),
                maxLength(messageConfigs.line1.maxChar),
              ),
              noSpecialChars,
            },
            line2: {
              maxLength: helpers.withMessage(
                i18n
                  .t('cart.modules.addItem.personalizedMessage.errors.maxLength', { max: messageConfigs.line2.maxChar })
                  .toString(),
                maxLength(messageConfigs.line2.maxChar),
              ),
              noSpecialChars,
            },
            line3: {
              maxLength: helpers.withMessage(
                i18n
                  .t('cart.modules.addItem.personalizedMessage.errors.maxLength', { max: messageConfigs.line3.maxChar })
                  .toString(),
                maxLength(messageConfigs.line3.maxChar),
              ),
              noSpecialChars,
            },
          },
          pocketsQuantity: {},
        }))

        const v$ = useVuelidate(rules, form)

        const fetchCartItemRequest: () => Result<CartItemRequest> = () => {
          v$.value.$touch()
          if (v$.value.$invalid) {
            return Result.err(new Error(getErrorMessage(v$.value.$errors)))
          }
          if (v$.value.amount.$model == null) {
            return Result.err(new Error('cart.modules.addItem.error.field.required'))
          }

          return Result.ok({
            itemType: v$.value.itemType.$model,
            deliveryPointReference: v$.value.deliveryPoint.$model?.reference ?? null,
            distributionPointReference: v$.value.deliveryPoint.$model?.distributionRef ?? null,
            eventCode:
              cart.meta.articleCode === ArticleCode.CARTE_KADEOS_CULTURE ? 75 : v$.value.event.$model?.value ?? null,
            message: v$.value.message.$model,
            packagingQuantity: v$.value.quantity.$model,
            pocketsQuantity: 0,
            compositions: [
              {
                quantity: 1,
                titleValue: v$.value.amount.$model.value,
                employersContribution: 1,
              },
            ],
          })
        }
        const itemConfig = computed(() => config)
        return {
          events,
          itemConfigs: [config],
          itemConfig,
          deliveryPointTitle: 'cart.modules.settings.deliveryPoint.titleCard',
          messageConfigs,
          v$,
          itemTypeImage: computed(() => article?.image ?? null),
          showDeliveryPoint,
          showEvent,
          showPocket: computed(() => false),
          pocketImage: null,
          addAction: useAction({
            id: 'add',
            name: record?.item ? 'cart.modules.addItem.editAction' : 'cart.modules.addItem.action',
            type: ActionType.Default,
            refresh: true,
            async execute() {
              const requestItemResult = fetchCartItemRequest()

              if (requestItemResult.isErr) {
                return Result.err(requestItemResult.error)
              }

              let result: Result<CartItem>
              if (record?.item == null) {
                result = await apiV4.carts.postItem(cart.remote.id, requestItemResult.value)
              } else {
                result = await apiV4.carts.putItem(cart.remote.id, record.item.id, requestItemResult.value)
              }

              if (result.isErr) {
                return Result.err(result.error)
              }

              const trackingStore = useTrackingStore()
              const item = useCKSProductItemTracking(
                cart.meta.productCode,
                cart.meta.articleCode,
                cart.meta.isNominative,
                result.value,
              )

              if (item) {
                trackingStore.trackEvent({
                  id: 'add_to_cart',
                  data: {
                    cart_id: cart.remote.id.toString(),
                    currency: 'EUR',
                    value: result.value.totalAmount / 100,
                    items: [item],
                  },
                })
              }

              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default addCKSProduct
