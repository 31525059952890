import type { AxiosError } from 'axios'
import { type ApiAxiosError, ApiError } from '../common/errors'
import { UNKNOWN_ERROR_MESSAGE } from '@/types/ResultError'

export enum ApiAuthErrorType {
  // Login
  CONNECTION_INTERLOCUTOR_KO = 'CONNECTION_INTERLOCUTOR_KO',
  USER_LOCKED = 'user_locked',
  USER_AUTHN_LOCKED = 'USER_AUTHN_LOCKED',
  UNAUTHORIZED = 'unauthorized',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  CONNECTION_ACTIVATION_ACCOUNT = 'CONNECTION_ACTIVATION_ACCOUNT',
  USER_EXC_INACTIVE = 'USER_EXC_INACTIVE',
  CONNECTION_ROLE_KO = 'CONNECTION_ROLE_KO',
  INVALID_RECAPTCHA = 'invalid_recaptcha',
  SIRET_WITHOUT_CLIENT_ACCOUNT = 'SIRET_WITHOUT_CLIENT_ACCOUNT',

  // Activation
  CLIENT_NOT_FOUND_IN_PANDA = 'CLIENT_NOT_FOUND_IN_PANDA',
  CREATE_ACCOUNT_EXC_ACTIVE = 'CREATE_ACCOUNT_EXC_ACTIVE',
  ASSOCIATE_ACCOUNT_EXC_ACTIVE = 'ASSOCIATE_ACCOUNT_EXC_ACTIVE',
  CREATE_ACCOUNT_EXC_INACTIVE = 'CREATE_ACCOUNT_EXC_INACTIVE',
  ASSOCIATE_ACCOUNT_EXC_INACTIVE = 'ASSOCIATE_ACCOUNT_EXC_INACTIVE',
  EMPTY_EMAIL = 'EMPTY_EMAIL',
  ACTIVATION_INTERLOCUTOR_KO = 'ACTIVATION_INTERLOCUTOR_KO',
  ACTIVATION_ROLE_KO = 'ACTIVATION_ROLE_KO',
  USER_CANNOT_SET_THIS_ROLE = 'USER_CANNOT_SET_THIS_ROLE',
  CODE_CLIENT_NOT_NUMBER = 'CODE_CLIENT_NOT_NUMBER',
  SIRET_WITH_MULTI_ACCOUNT = 'SIRET_WITH_MULTI_ACCOUNT',

  // Identify
  TOKEN_NOT_VALID = 'TOKEN_NOT_VALID',

  // QuestionSecret
  QUESTION_SECRET_NOT_FOUND = 'QUESTION_SECRET_NOT_FOUND',

  // Check Question Secret
  QUESTION_SECRETE_KO = 'QUESTION_SECRETE_KO',

  // ResetPassword
  SEND_EMAIL_CHANGE_MDP_KO = 'SEND_EMAIL_CHANGE_MDP_KO',

  // Credentials
  CHANGE_PASSWORD_STATUT_INVALID = 'CHANGE_PASSWORD_STATUT_INVALID',

  MISSING_TOKEN = 'MISSING_TOKEN',
}

export const errorMessage: Record<ApiAuthErrorType, string> = {
  [ApiAuthErrorType.CONNECTION_INTERLOCUTOR_KO]: 'Nous n’avons pas trouvé de compte associé à cet email.',
  [ApiAuthErrorType.USER_LOCKED]:
    "Votre compte est verrouillé suite à un trop grand nombre d'essais infructueux. Merci de contacter le Centre de relation client au 0821 23 24 25.",
  [ApiAuthErrorType.USER_AUTHN_LOCKED]:
    'Suite à un trop grand nombre d’essais infructueux, votre compte est verrouillé pendant 1h.',
  [ApiAuthErrorType.UNAUTHORIZED]: "Vous n'êtes pas autorisé à vous connecter à l'espace client",
  [ApiAuthErrorType.AUTHENTICATION_FAILED]:
    'Votre adresse email ou votre mot de passe est incorrect, veuillez vérifier les informations saisies.',
  [ApiAuthErrorType.USER_EXC_INACTIVE]: 'Votre compte à été désactivé par votre superviseur',
  [ApiAuthErrorType.CONNECTION_ROLE_KO]: "Vous n'êtes pas autorisé à vous connecter à l'espace client avec cet email.",
  [ApiAuthErrorType.CONNECTION_ACTIVATION_ACCOUNT]:
    "Votre email n'est pas encore associé à un compte, merci de remplir les champs ci-dessous pour pouvoir accéder à votre espace client.",
  [ApiAuthErrorType.INVALID_RECAPTCHA]: 'Votre réponse au captcha est invalide. Merci de réessayer',
  [ApiAuthErrorType.CLIENT_NOT_FOUND_IN_PANDA]: 'Votre compte ne peut pas être créé pour le moment',
  [ApiAuthErrorType.CREATE_ACCOUNT_EXC_ACTIVE]: 'Ce compte existe déjà et est activé, veuillez vous connecter.',
  [ApiAuthErrorType.ASSOCIATE_ACCOUNT_EXC_ACTIVE]:
    'Un compte associé à cet email existe déjà. Veuillez vous connecter puis associer les comptes client souhaités via le menu Mon Profil/Vos comptes clients.',
  [ApiAuthErrorType.CREATE_ACCOUNT_EXC_INACTIVE]:
    "Nous venons de vous envoyer un email contenant un lien d'activation sur lequel vous devez cliquer pour continuer le process d'activation de votre compte.\nSi vous n'avez pas reçu l'email, veuillez le renvoyer à nouveau&nbsp;:",
  [ApiAuthErrorType.ASSOCIATE_ACCOUNT_EXC_INACTIVE]:
    "Nous venons de vous envoyer un email contenant un lien d'activation sur lequel vous devez cliquer pour continuer le process d'activation de votre compte.\nVeuillez ensuite vous connecter puis associer les comptes client souhaités via le menu Mon Profil/Vos comptes clients.",
  [ApiAuthErrorType.EMPTY_EMAIL]: "L'email saisi ne correspond pas au client indiqué.",
  [ApiAuthErrorType.ACTIVATION_INTERLOCUTOR_KO]: 'Ce compte ne peut pas être associé sur cette division.',
  [ApiAuthErrorType.ACTIVATION_ROLE_KO]: 'Ce compte ne peut pas être associé sur cette division.',
  [ApiAuthErrorType.USER_CANNOT_SET_THIS_ROLE]: 'Ce compte ne peut pas être associé sur cette division.',
  [ApiAuthErrorType.CODE_CLIENT_NOT_NUMBER]: 'Le code client doit être un nombre',
  [ApiAuthErrorType.SIRET_WITH_MULTI_ACCOUNT]:
    'Ce SIRET est associé à plusieurs comptes, merci de renseigner votre numéro client',
  [ApiAuthErrorType.SEND_EMAIL_CHANGE_MDP_KO]: "L'envoi de l'email a échoué. Le service technique en a été informé",
  [ApiAuthErrorType.CHANGE_PASSWORD_STATUT_INVALID]: "Vous n'avez pas la possibilité de changer votre mot de passe",
  [ApiAuthErrorType.QUESTION_SECRETE_KO]: 'Réponse incorrecte',
  [ApiAuthErrorType.TOKEN_NOT_VALID]: "Le lien d'activation est invalide ou expiré, merci d'en demander un nouveau",
  [ApiAuthErrorType.QUESTION_SECRET_NOT_FOUND]: 'La question secrète est introuvable',
  [ApiAuthErrorType.MISSING_TOKEN]: 'Le token est introuvable',
  [ApiAuthErrorType.SIRET_WITHOUT_CLIENT_ACCOUNT]:
    'Votre SIRET ne concerne aucun compte connu. Merci de nous contacter au 0821 23 23 25 (0,12cts/min + prix appel).',
}

export function getError(key: string): string {
  return errorMessage[key as ApiAuthErrorType] ?? UNKNOWN_ERROR_MESSAGE
}

export type ApiLoginAxiosError = AxiosError<{ error: string; error_description: string }>

export class ApiLoginError extends ApiError {
  axiosError: ApiLoginAxiosError

  constructor(error: ApiLoginAxiosError) {
    super(error)

    this.axiosError = error
    this.name = 'ApiLoginError'
    this.message = getError(this.code)
  }
}

export class ApiAuthError extends ApiError {
  constructor(error: ApiAxiosError) {
    super(error)

    this.name = 'ApiAuthError'
    this.message = getError(this.code)
  }
}
