import univers from '@/assets/constantes/univers.json'

export default {
  path: univers.TR.ROOT.path,
  meta: univers.TR.ROOT.meta,
  component: () => import('@/views/DefaultRouter/Root/index.vue'),
  children: [
    {
      path: univers.TR.UNIVERS.path,
      meta: univers.TR.UNIVERS.meta,
      name: univers.TR.UNIVERS.name,
      component: () => import('@/views/Universe/index.vue'),
    },
  ],
}
