import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { accessor } from '..'
import { useStore as useDrawerStore } from '../drawer'
import { useModalStore } from '../modal'
import { ProductCode } from '@/variables/ProductCode'
import contentful from '@/services/contentful'
import type { CFOrderConfirmationCard } from '@/services/contentful/types/CFOrderConfirmationCard'

export const useDigitalizationStore = defineStore('digitalization', () => {
  const drawerStore = useDrawerStore()
  const modalStore = useModalStore()

  const canDigitalize = computed(
    () =>
      accessor.session.hasContract([ProductCode.TICKET_RESTAURANT]) &&
      (!accessor.session.hasContract([ProductCode.CARTE_TICKET_RESTAURANT]) ||
        accessor.session.contracts.find((contrat) => !contrat.orderAllowed && ProductCode.CARTE_TICKET_RESTAURANT)),
  )

  const openLeadDrawer = () => {
    drawerStore.push('lead-drawer', () => import('@/components/FormLead.vue'), {
      productId: 'CTR',
      productName: 'Carte Ticket Restaurant',
      source: 'LEADONECLICK',
    })
  }

  const fetchHomeBanner = async () => {
    if (!canDigitalize.value) {
      return null
    }

    const result = await contentful.banner.fetchBanner('digitalization')
    if (result.isErr) {
      return null
    }

    return {
      ...result.value,
      cta: result.value.cta
        ? {
            ...result.value.cta,
            action: () => {
              openLeadDrawer()
            },
          }
        : undefined,
    }
  }

  const showHomeModal = ref(true)

  const loadHomeModal = async () => {
    if (!showHomeModal.value || !canDigitalize.value) {
      return null
    }

    const result = await contentful.modal.fetchModal('digitalization')
    if (result.isErr) {
      return null
    }

    modalStore.push(result.value.id, () => import('@/components/popins/ContentfulPopin.vue'), {
      id: result.value.id,
      cfModal: { ...result.value },
      mainClick: () => {
        modalStore.pop(result.value.id)
        openLeadDrawer()
      },
      cancelClick: () => {
        modalStore.pop(result.value.id)
      },
    })

    showHomeModal.value = false
  }

  const fetchOrderConfirmationCard = async (): Promise<CFOrderConfirmationCard | null> => {
    if (!canDigitalize.value) {
      return null
    }

    const result = await contentful.orderConfirmationCard.fetchOrderConfirmationCard('digitalization')
    if (result.isErr) {
      return null
    }

    return {
      ...result.value,
      cta: {
        ...result.value.cta,
        action: () => {
          openLeadDrawer()
        },
      },
    }
  }

  return {
    canDigitalize,
    openLeadDrawer,
    fetchHomeBanner,
    loadHomeModal,
    fetchOrderConfirmationCard,
  }
})
