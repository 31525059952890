import { Result } from '@badrap/result'
import payment from '@/store/cart/cartWorkflows/common/steps/payment'
import type { PaymentWorkflow } from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'
import apiV4 from '@/services/apiV4'
import { useStore as useFeatureFlagStore } from '@/store/featureFlag'
import { FeatureFlag } from '@/store/featureFlag/model'
import type { DeliveryInformations } from '@/services/carts/types'
import { ActionType } from '@/types/Action'
import { moduleConfig as missingEmailsModuleConfig } from '@/components/Cart/Module/MissingEmails/workflow'
import { useIsRestricted } from '@/composables/useIsRestricted'

const step: StepBuilder<PaymentWorkflow> = {
  ...payment,
  async prepareStep(cart) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: Record<string, any> = {}

    if (payment.prepareStep) {
      const result = await payment.prepareStep(cart)

      if (result.isErr) {
        return Result.err(result.error)
      }

      data = result.value
    }

    const featureFlagStore = useFeatureFlagStore()
    const isRestricted = useIsRestricted()

    if (
      featureFlagStore.isEnabled(FeatureFlag.SmarterEmailCollection) &&
      cart.meta.isNominative &&
      !isRestricted.value
    ) {
      const totalItemsWithoutEmail = await apiV4.carts.headItems(cart.remote.id, {
        isWithoutEmailBeneficiary: true,
      })

      if (totalItemsWithoutEmail.isErr || totalItemsWithoutEmail.value === 0) {
        return Result.ok({
          ...data,
          totalItemsWithoutEmail: 0,
          itemsWithoutEmail: [],
        })
      }

      const itemsWithoutEmail = await apiV4.carts.fetchItems(cart.remote.id, {
        isWithoutEmailBeneficiary: true,
        pageSize: Math.max(totalItemsWithoutEmail.value, 100),
      })

      if (itemsWithoutEmail.isErr) {
        return Result.err(itemsWithoutEmail.error)
      }

      return Result.ok({
        ...data,
        totalItemsWithoutEmail: totalItemsWithoutEmail.value,
        itemsWithoutEmail: itemsWithoutEmail.value.items,
      })
    }

    return Result.ok({ ...data })
  },
  useStep(
    cart,
    workflow,
    record: {
      deliveryInformations: Record<number, DeliveryInformations>
      totalItems: number
      totalItemsWithoutEmail?: number
    },
    useAction,
  ) {
    const useStep = payment.useStep(cart, workflow, record, useAction)
    return {
      ...useStep,
      useWorkflow() {
        const useWorkflow = useStep.useWorkflow()

        return {
          ...useWorkflow,
          missingEmailsAction: useAction({
            id: missingEmailsModuleConfig.id,
            name: 'missing-emails',
            type: ActionType.Drawer,
            refresh: false,
            payload: record,
          }),
          showMissingEmails: record.totalItemsWithoutEmail != null && record.totalItemsWithoutEmail > 0,
        }
      },
    }
  },
}

export default step
