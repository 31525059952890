import { Result } from '@badrap/result'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import { defineStore } from 'pinia'
import { accessor } from '..'
import {
  type FrontCart,
  type CartState,
  type FrontCarts,
  FrontCartType,
  type RemoteCart,
  type RemoteCartType,
  FrontCartStatus,
  type EditCart,
  type FrontCartMeta,
} from './model'
import apiV4 from '@/services/apiV4'
import type { CartsError } from '@/services/carts/errors'
import type { Cart } from '@/services/carts/typesV2'
import { fetchTerms } from '@/types/Terms'
import { generateId } from '@/utils/id'
import { getInLocalStorage, setInLocalStorage } from '@/utils/localStorage'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'
import { clickUrl } from '@/parameters'
import type { Product } from '@/assets/constantes/Univers'
import { DataStoreKey } from '@/services/dataStore/types'
import contentful from '@/services/contentful'
import type { CFProduct } from '@/services/contentful/types/CFProduct'
import type { CFEvent } from '@/services/contentful/types/CFEvent'
import type { CFImportField } from '@/services/contentful/types/CFImportField'
import { useIsSoge } from '@/composables/useIsSoge'

const CARTS_KEY = 'carts'

export const useStore = defineStore('carts', {
  state: (): CartState => {
    let carts = getInLocalStorage<FrontCarts>(CARTS_KEY) ?? {}

    carts = Object.values(carts).reduce<FrontCarts>((r, cart) => {
      if (differenceInWeeks(new Date(cart.createdAt), new Date()) < 1) {
        r[cart.localId] = cart
      }
      return r
    }, {})

    setInLocalStorage(CARTS_KEY, carts)

    return { carts, events: null, products: null, importFields: null }
  },
  getters: {
    product: (state) => (productCode: ProductCode) => state.products?.find((product) => product.code === productCode),
    article:
      (state) =>
      (productCode: ProductCode, articleCode: ArticleCode | null = null) =>
        state.products
          ?.find((product) => product.code === productCode)
          ?.articles.find(
            (article) =>
              article.code === articleCode || (articleCode === null && article.code === ArticleCode.NO_PRODUCT),
          ),
    cartItemConfigs: (state) => (productCode: ProductCode, articleCode?: ArticleCode | null) => {
      return state.products
        ?.find((product) => product.code === productCode)
        ?.articles.find(
          (article) =>
            article.code === articleCode || (article.code === ArticleCode.NO_PRODUCT && articleCode === null),
        )?.cartItemConfigs
    },
    eventsByProductCode: (state) => (productCode: ProductCode) =>
      state.events?.filter((event) => event.products.find((product) => product === productCode)) ?? [],
  },
  actions: {
    setCarts(carts: FrontCarts | null) {
      this.carts = carts ?? {}
      setInLocalStorage(CARTS_KEY, carts)
    },

    setCart(cart: FrontCart) {
      this.carts[cart.localId] = cart
      setInLocalStorage(CARTS_KEY, this.carts)
    },

    setEvents(events: CFEvent[]) {
      this.events = events
    },

    setProducts(products: CFProduct[]) {
      this.products = products
    },

    setImportFields(importFields: CFImportField[]) {
      this.importFields = importFields
    },

    async refresh({ all, type, localId }: { all?: boolean; type?: FrontCartType; localId?: string }) {
      let carts = Object.values(this.carts)
      if (!all) {
        carts = Object.values(this.carts).filter((cart) => {
          const isSameId = localId != null ? cart.localId === localId : true
          const isSameType = type != null ? cart.type === type : true
          return isSameId && isSameType
        })
      }

      await Promise.all(
        carts.map(async (cart) => {
          if (cart.type !== FrontCartType.Local) {
            const result = await getRemoteCart(cart, cart.type, cart.remote.id)
            if (result.isOk) {
              cart = result.value
            }

            this.setCart(cart)
          }
        }),
      )
    },

    async bind(
      localId: string,
      type: FrontCartType,
      remoteId: number,
    ): Promise<Result<RemoteCart, CartsError> | Result<RemoteCart, Error>> {
      let cart: FrontCart = this.carts[localId]

      if (type === FrontCartType.Local) {
        return Result.err(new Error('cart.errors.isLocal'))
      }

      const result = await getRemoteCart(cart, type, remoteId)
      if (result.isErr) {
        return Result.err(result.error)
      }

      cart = result.value

      this.setCart(cart)

      return Result.ok(cart)
    },

    async fetchCartByRemoteId(id: number, type: RemoteCartType) {
      const frontCart = Object.values(this.carts).find(
        (cart) => cart.type !== FrontCartType.Local && cart.type === type && cart.remote.id === id,
      )

      if (frontCart) {
        return Result.ok(frontCart.localId)
      }

      const result = await getCart(id, type)
      if (result.isErr) {
        return Result.err(result.error)
      }

      const cart = result.value

      const terms = fetchTerms(cart.description.productCode, cart.description.articleCode, cart.options.isMixed)
      if (!terms) {
        return Result.err(new Error('cart.errors.termsNotFound'))
      }

      const contract = accessor.session.contracts.find(
        (contract) =>
          contract.product.code === cart.description.productCode &&
          (cart.description.articleCode === null ||
            (contract.articles.length > 0 &&
              contract.articles.find((article) => article.code === cart.description.articleCode))),
      )

      if (contract && !contract.isActive) {
        return Result.err(new Error('cart.errors.inactiveContract'))
      }

      const localId = generateId()

      const meta: FrontCartMeta = {
        productCode: cart.description.productCode,
        articleCode: cart.description.articleCode ?? null,
        isPFA: accessor.session.clientProducts[cart.description.productCode]?.accountingSettings?.isPFA ?? false,
        isNominative: cart.options.isNominative,
        isMixed: cart.options.isMixed,
        isMultiLoading: cart.options.isMultiLoading,
        isPlasticless: cart.options.isPlasticless,
        isCrossSell: contract == null,
        discountCode: cart.paymentOptions.discountCode,
        isHomeDelivery: cart.options.isHomeDelivery,
        isBenefitsCalculator: false,
        dataStore: {
          [DataStoreKey.Amount]: null,
          [DataStoreKey.Event]: null,
          [DataStoreKey.DeliveryPoint]: null,
          [DataStoreKey.ValueOfTitle]: null,
        },
        visual: accessor.session.getProductImg(
          cart.description.productCode,
          cart.description.articleCode,
          cart.options.isMixed,
        ),
      }

      this.setCart({
        localId,
        type,
        status: FrontCartStatus.New,
        meta,
        createdAt: new Date(),
        remote: result.value,
        terms,
        howTo: fetchHowTo(meta),
      })

      return Result.ok(localId)
    },

    async new({
      productCode,
      articleCode,
      discountCode,
      isNominative,
      isMixed,
      isHomeDelivery,
      isBenefitsCalculator,
    }: {
      productCode: ProductCode
      articleCode: ArticleCode | null
      product: Product
      discountCode?: string
      isNominative?: boolean
      isMixed?: boolean
      isHomeDelivery?: boolean
      isBenefitsCalculator?: boolean
    }) {
      const localId = generateId()
      const productConfig = accessor.session.clientProducts[productCode]
      if (productConfig == null) {
        return Result.err(new Error('cart.errors.productConfigNotFound'))
      }

      const terms = fetchTerms(productCode, articleCode ?? null, isMixed ?? false)
      if (!terms) {
        return Result.err(new Error('cart.errors.termsNotFound'))
      }

      const contract = accessor.session.contracts.find(
        (contract) =>
          contract.product.code === productCode &&
          (articleCode === null ||
            (contract.articles.length > 0 && contract.articles.find((article) => article.code === articleCode))),
      )

      if (contract && !contract.isActive) {
        return Result.err(new Error('cart.errors.inactiveContract'))
      }

      const isSoge = useIsSoge()

      const meta: FrontCartMeta = {
        productCode,
        articleCode,
        discountCode: discountCode ?? null,
        isPFA: productConfig.accountingSettings?.isPFA ?? false,
        isNominative: isNominative ?? true,
        isMixed: (productConfig.clientOptions.mixedOrderAllowed && isMixed) ?? false,
        isMultiLoading: productConfig.clientOptions.multiloadingAllowed ?? false,
        isPlasticless: productConfig.clientOptions.plasticlessAllowed ?? false,
        isCrossSell: contract == null,
        isHomeDelivery: isBenefitsCalculator
          ? true
          : isSoge.value && productCode === ProductCode.CARTE_TICKET_RESTAURANT
          ? true
          : isHomeDelivery ?? null,
        isBenefitsCalculator: isBenefitsCalculator ?? null,
        dataStore: {
          [DataStoreKey.Amount]: null,
          [DataStoreKey.Event]: null,
          [DataStoreKey.DeliveryPoint]: null,
          [DataStoreKey.ValueOfTitle]: null,
        },
        visual: accessor.session.getProductImg(productCode, articleCode, isMixed),
      }

      this.setCart({
        localId,
        type: FrontCartType.Local,
        status: FrontCartStatus.New,
        meta,
        createdAt: new Date(),
        terms,
        howTo: fetchHowTo(meta),
      })

      return Result.ok(localId)
    },

    async edit({ localId, meta }: EditCart) {
      const frontCart = Object.values(this.carts).find((cart) => cart.localId === localId)

      if (!frontCart) {
        return Result.err(new Error('cart.errors.cartNotFound'))
      }

      frontCart.meta = {
        ...frontCart.meta,
        ...meta,
      }

      await this.setCart(frontCart)

      return Result.ok(localId)
    },

    async fetchEventConfig() {
      const result = await contentful.event.fetchEvents()

      if (result.isOk) {
        this.setEvents(result.value)
      }

      return result
    },

    async fetchProducts() {
      if (this.products === null) {
        const result = await contentful.product.fetchProducts()

        if (result.isOk) {
          this.setProducts(result.value)
        }
      }

      return this.products
    },

    async fetchImportFields() {
      if (this.importFields === null) {
        const result = await contentful.importFields.fetchImportFields()

        if (result.isOk) {
          this.setImportFields(result.value)
        } else {
          return result
        }
      }

      return Result.ok(this.importFields)
    },
  },
})

async function getCart(id: number, type: RemoteCartType): Promise<Result<Cart, Error>> {
  let cart: Cart

  switch (type) {
    case FrontCartType.Cart: {
      const result = await apiV4.carts.fetchCart(id)
      if (result.isErr) {
        return Result.err(result.error)
      }
      cart = result.value

      break
    }
    default:
      // TODO: call preOrder api route
      return Result.err(new Error('Is PreOder'))
  }

  return Result.ok(cart)
}

async function getRemoteCart(
  frontCart: FrontCart,
  type: RemoteCartType,
  id: number,
): Promise<Result<RemoteCart, Error>> {
  const result = await getCart(id, type)
  if (result.isErr) {
    return Result.err(result.error)
  }
  const remote = result.value

  return Result.ok({
    ...frontCart,
    type,
    remote,
  })
}

function fetchHowTo(meta: FrontCartMeta): string | null {
  switch (meta.productCode) {
    case ProductCode.KADEOS_CONNECT:
      return `${clickUrl}Documents_Espace_Client/Commande/commande-kadeos-connect-comment-faire.pdf`
    case ProductCode.KADEOS:
      return `${clickUrl}Documents_Espace_Client/Commande/commande-kadeos-comment-faire.pdf`
    case ProductCode.CARTE_KADEOS: {
      if (meta.isNominative) {
        return `${clickUrl}Documents_Espace_Client/Commande/commande-carte-ks-nominatif-comment-faire.pdf`
      }
      return `${clickUrl}Documents_Espace_Client/Commande/commande-carte-ks-anonyme-comment-faire.pdf`
    }
    case ProductCode.TICKET_RESTAURANT:
    case ProductCode.CARTE_TICKET_RESTAURANT: {
      if (meta.isMixed) {
        return `${clickUrl}Documents_Espace_Client/Commande/commande-mixte-comment-faire.pdf`
      } else if (meta.isPlasticless) {
        return `${clickUrl}Documents_Espace_Client/Commande/commande-ticket-restaurant-virtuel-import-saisie-manuelle-comment-faire.pdf`
      } else if (meta.isNominative) {
        return `${clickUrl}Documents_Espace_Client/Commande/commande-${
          meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT ? 'carte-' : ''
        }ticket-restaurant-import-saisie-manuelle-comment-faire.pdf`
      }

      return `${clickUrl}Documents_Espace_Client/Commande/commande-ticket-restaurant-anonyme-comment-faire.pdf`
    }
    default:
      return null
  }
}
