import axios from 'axios'
import _trimEnd from 'lodash/trimEnd'
import proxyfy from './utils/proxyfy'
import accountingDocumentsApi from './uTR/apis/accountingDocumentsApi'
import cgvApi from './uTR/apis/cgvApi.js'
import contractsApi from './uTR/apis/contracts/contractsV3.js'
import deliveryPointApi from './uTR/apis/deliveryPointsApi.js'
import earliestDeliveryDates from './uTR/apis/earliestDeliveryDateApi.js'
import employeesApi from './uTR/apis/employeesApi.js'
import facialValuesApi from './uTR/apis/facialValuesApi.js'
import generiqueCallApi from './uTR/apis/generiqueCallApi.js'
import historyApi from './uTR/apis/historyApi.js'
import importApi from './uTR/apis/imports/importV3.js'
import managementApi from './uTR/apis/managementApi.js'
import orderBasketApi from './uTR/apis/orderBasket/orderV3.js'
import orderBeneficiariesApi from './uTR/apis/orderBeneficiariesApi.js'
import pagingCsvApi from './uTR/apis/pagingCsvApi.js'
import paymentMeansApi from './uTR/apis/paymentMeansApi.js'
import termsOfSalesApi from './uTR/apis/termsOfSalesApi.js'
import tunnelAccessibilityApi from './uTR/apis/tunnelAccessibilityApi.js'
import utilsApi from './uTR/apis/utilsApi.js'
import downloadSummary from './uKS/apis/downloadSummaryApi.js'
import interimImport from './uTR/apis/interim/import.js'
import clickApi from './uTR/apis/clickApi.js'
import clickApiUKs from './uKS/apis/clickApiUKs.js'
import interimClickCsv from './uTR/apis/interim/clickCsv'
import faqApi from './uTR/apis/faqApi.js'
import importRedup from './uTR/apis/imports/importRedUp.js'
import setAxiosInterceptors from '@/services/utils/interceptors/AxiosInterceptors'
import * as parameters from '@/parameters'

axios.defaults.baseURL =
  /*!parameters.useMock
  ? */ _trimEnd(parameters.clientApiUrl, '/') + '/'
/*: _trimEnd(window.location.origin, '/')*/

setAxiosInterceptors(axios)

const proxyfyAxios = proxyfy(axios)

const clickInstance = axios.create({
  baseURL: parameters.clickUrl,
})

const redUpInstance = axios.create({
  baseURL: `${parameters.clientUrlRedUp}`,
})

const faqInstance = axios.create({
  baseURL: 'https://myedenred-fr.eu.edenred.io/v1/referentials',
  headers: {
    'X-Client-Id': '73cb611448194505a6f45ced981d957d',
    'X-Client-Secret': '132786D689f448A4A7036A1ef1898BfF',
  },
})
export default {
  v3: {
    instance: proxyfyAxios,
    apis: {
      accountingDocumentsApi,
      cgvApi,
      contractsApi,
      deliveryPointApi,
      earliestDeliveryDates,
      employeesApi,
      facialValuesApi,
      generiqueCallApi,
      historyApi,
      importApi,
      managementApi,
      orderBasketApi,
      orderBeneficiariesApi,
      pagingCsvApi,
      paymentMeansApi,
      termsOfSalesApi,
      tunnelAccessibilityApi,
      utilsApi,
      downloadSummary,
      interimImport,
    },
  },
  click: {
    instance: clickInstance,
    apis: {
      clickApi,
      clickApiUKs,
      interimClickCsv,
    },
  },
  faq: {
    instance: faqInstance,
    apis: {
      faqApi,
    },
  },
  redUp: {
    instance: redUpInstance,
    apis: {
      importRedup,
    },
  },
}
