import { Result } from '@badrap/result'
import { useVuelidate } from '@vuelidate/core'
import { computed, reactive, ref } from 'vue'
/* eslint-disable import/no-duplicates */
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { helpers, required, integer, minValue } from '@vuelidate/validators'
import { useDeliveryPointWorkflow } from './useDeliveryPointWorkflow'
import { useValueOfTitleWorkflow } from './useValueOfTitleWorkflow'
import apiV4 from '@/services/apiV4'
import { fetchCartItemType, type CartItemRequest, CartItemType } from '@/services/carts/types'
import type { ValueOfTitleData } from '@/types/ValueOfTitle'
import { DataStoreKey } from '@/services/dataStore/types'
import type { UseEditItemFormWorkflow } from '@/components/Cart/Module/EditItemBeneficiary/workflow'
import { ProductCode } from '@/variables/ProductCode'

export const useEditItemFormWorkflow: UseEditItemFormWorkflow = (cart, item) => {
  const date = new Date()
  const maxPackagingQuantity = differenceInBusinessDays(endOfMonth(date), startOfMonth(date))

  const rules = computed(() => ({
    quantity: {
      required: helpers.withMessage('cart.modules.addItemBeneficiary.itemFrom.quantity.errors.required', required),
      minValue: helpers.withMessage(() => `Saisissez une quantité entre ${1} et ${maxPackagingQuantity}`, minValue(0)),
      integer: helpers.withMessage('cart.modules.addItemBeneficiary.itemFrom.quantity.errors.integer', integer),
    },
  }))

  const dataStoreDelivery = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
    ? cart.meta.dataStore[DataStoreKey.DeliveryPoint][cart.meta.productCode]
    : null

  const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
  let defaultData: ValueOfTitleData | undefined = undefined
  if (item && item.compositions[0].titleValue && item.compositions[0].employersContribution) {
    defaultData = {
      value: item.compositions[0].titleValue,
      employersContribution: item.compositions[0].employersContribution * 100,
    }
  } else if (dataStoreValueOfTitle) {
    defaultData = {
      value: dataStoreValueOfTitle.value,
      employersContribution: dataStoreValueOfTitle.employersContribution,
    }
  }

  const state = reactive({
    quantity:
      (item.itemType === CartItemType.Booklet || item.itemType === CartItemType.Card
        ? item.packagingQuantity
        : item.compositions[0].quantity) ?? 0,
  })
  const v$ = useVuelidate(rules, state)

  return {
    v$,
    useValueOfTitleWorkflow: useValueOfTitleWorkflow([cart.meta.productCode], 'input', defaultData),
    useDeliveryPointWorkflow: useDeliveryPointWorkflow(ref(cart), dataStoreDelivery, {
      for: 'input',
      showType: false,
      productCode: cart.meta.productCode,
      showCardBenefDescription: cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT,
    }),
    submit: (submitValueOfTitle, submitDeliveryPoint) => async (beneficiary) => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return Result.err(new Error(v$.value.$errors[0].$message.toString()))
      }

      const resultAmount = await submitValueOfTitle()
      if (resultAmount.isErr) {
        return Result.err(resultAmount.error)
      }

      const resultDeliveryPoint = await submitDeliveryPoint()
      if (resultDeliveryPoint.isErr) {
        return Result.err(resultDeliveryPoint.error)
      }

      const body: CartItemRequest = {
        itemType: fetchCartItemType(cart.remote.description.productCode, cart.remote.options.isPlasticless),
        deliveryPointReference: resultDeliveryPoint.value.reference ?? null,
        distributionPointReference: resultDeliveryPoint.value.distributionPoint?.reference ?? null,
        packagingQuantity: 1,
        compositions: [
          {
            quantity: v$.value.quantity.$model,
            titleValue: resultAmount.value.value,
            employersContribution: resultAmount.value.employersContribution / 100,
          },
        ],
        beneficiary,
      }

      const resultItem = await apiV4.carts.putItem(cart.remote.id, item.id, body)

      if (resultItem.isErr) {
        return Result.err(resultItem.error)
      }
      return Result.ok(undefined)
    },
  }
}
