import { useVuelidate } from '@vuelidate/core'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import { reactive, type Ref } from 'vue'
import { Result } from '@badrap/result'
import type { AxiosError } from 'axios'
import type {
  DeliveryPointConfig,
  DeliveryPointState,
  SettingFor,
  UseDeliveryPointWorkflow,
  UseVuelidateDeliveryPoint,
} from '@/components/Cart/Module/common/workflow'
import { getErrorMessage } from '@/utils/vuelidate'
import apiV4 from '@/services/apiV4'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import type { LocalDeliveryPoint, RemoteCart } from '@/store/cart/model'
import { accessor } from '@/store'

export const useVuelidateDeliveryPoint: UseVuelidateDeliveryPoint = (
  cartRef: Ref<RemoteCart | null>,
  defaultData: LocalDeliveryPoint | null,
  settingFor: SettingFor,
) => {
  if (cartRef.value === null) {
    throw new Error('cart.errors.cartNotFound')
  }

  const { value: cart } = cartRef

  const state = reactive<DeliveryPointState>({
    type: defaultData?.type ?? cart.meta.isNominative ? DeliveryPointType.PL_BENEF : DeliveryPointType.PL_SITE,
    isMultiDeliveryPoint: settingFor !== 'input' ? defaultData?.isMulti ?? false : false,
    isMultiDistributionPoint: settingFor !== 'input' ? defaultData?.isMultiDistributionPoint ?? false : false,
    deliveryPoint: defaultData?.reference ? { value: defaultData?.reference, label: '' } : null,
    distributionPoint: defaultData?.distributionPoint?.reference
      ? { value: defaultData?.distributionPoint?.reference, label: '' }
      : null,
  })

  const rules = {
    type: { required },
    isMultiDeliveryPoint: { required: requiredIf(settingFor !== 'input') },
    deliveryPoint: {
      required: helpers.withMessage(
        'cart.modules.addItem.deliveryPoint.errors.deliveryPoint.required',
        requiredIf(() => !state.isMultiDeliveryPoint),
      ),
    },
    distributionPoint: {
      required: helpers.withMessage(
        'cart.modules.addItem.deliveryPoint.errors.distributionPoint.required',
        requiredIf(
          () =>
            settingFor === 'input' &&
            !!accessor.session.clientProducts[cart.meta.productCode]?.orderSettings.distributionPointMandatory,
        ),
      ),
    },
    isMultiDistributionPoint: {
      required: requiredIf(settingFor !== 'input'),
    },
  }

  const v$ = useVuelidate(rules, state)

  return {
    v$,
    submit: async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return Result.err(new Error(getErrorMessage(v$.value.$errors)))
      }
      if (!cart.meta.isMixed) {
        const result = await apiV4.carts.patchCart(cart.remote.id, {
          isHomeDelivery: v$.value.type.$model === DeliveryPointType.PL_BENEF,
          reference: cart.remote.description.orderReference,
          discountCode: cart.remote.paymentOptions.discountCode,
          deliveryDate: cart.remote.paymentOptions.deliveryDate,
        })

        if (result.isErr) {
          return Result.err(result.error)
        }
      }

      return Result.ok({
        id: v$.value.deliveryPoint.$model?.data?.id ?? null,
        type: v$.value.type.$model,
        reference: v$.value.deliveryPoint.$model?.data?.reference ?? null,
        isMulti: v$.value.isMultiDeliveryPoint.$model,
        distributionPoint: v$.value.distributionPoint.$model?.data
          ? {
              id: v$.value.distributionPoint.$model.data.id,
              reference: v$.value.distributionPoint.$model.data.reference,
            }
          : undefined,
        isMultiDistributionPoint: v$.value.isMultiDistributionPoint.$model,
        save: defaultData?.save != null,
      })
    },
  }
}

export const useDeliveryPointWorkflow: (
  cartRef: Ref<RemoteCart | null>,
  defaultData: LocalDeliveryPoint | null,
  config: DeliveryPointConfig,
) => UseDeliveryPointWorkflow = (cartRef, defaultData, config) => () => {
  const { v$, submit } = useVuelidateDeliveryPoint(cartRef, defaultData, config.for)
  return {
    config,
    v$,
    submit,
    fetchDelivery: async (type, page, filter, isDefault) => {
      try {
        const result = (
          await apiV4.deliveryPoints.getDeliveryPointsListV4({
            sort: 'Reference',
            sortDirection: 'ASC',
            page,
            pageSize: 100,
            productCode: config.productCode,
            deliveryPointsType: type,
            filter: filter ?? undefined,
            isDefault,
          })
        ).data

        return Result.ok(result)
      } catch (error) {
        return Result.err(error as AxiosError)
      }
    },
    fetchDistributions: (deliveryId) => {
      return apiV4.deliveryPoints.fetchDistributionPoints(config.productCode, deliveryId)
    },
  }
}
