import type { Banner } from '@/types/Banner'
import type { Image, SrcSet } from '@/types/Image'
import type { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

export enum Univers {
  TR = 'TR',
  KS = 'KS',
  TK = 'TK',
  TS = 'TS',
  CESU = 'CESU',
  BW = 'BW',
  FLEETPRO = 'FLEETPRO',
  TELETRAVAIL = 'TTE',
}

export const universByProductCode: Record<ProductCode, Univers> = {
  [ProductCode.TICKET_RESTAURANT]: Univers.TR,
  [ProductCode.CARTE_TICKET_RESTAURANT]: Univers.TR,
  [ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE]: Univers.TR,
  [ProductCode.KADEOS]: Univers.KS,
  [ProductCode.KADEOS_CONNECT]: Univers.KS,
  [ProductCode.KADEOS_UNIVERSEL]: Univers.KS,
  [ProductCode.CARTE_KADEOS]: Univers.KS,
  [ProductCode.TICKET_SERVICE]: Univers.TS,
  [ProductCode.TICKET_CESU]: Univers.CESU,
  [ProductCode.TICKET_CAP]: Univers.TS,
  [ProductCode.TICKET_FLEETPRO]: Univers.FLEETPRO,
  [ProductCode.BETTERWAY]: Univers.BW,
}

export function getProductCodesByUnivers(univers: Univers): ProductCode[] {
  const productCodes = Object.keys(universByProductCode)

  return productCodes.reduce<ProductCode[]>((r, code) => {
    const productCode = parseInt(code) as ProductCode

    if (universByProductCode[productCode] === univers) {
      r.push(productCode)
    }

    return r
  }, [])
}

type IObjectKeys = Record<string, boolean | string | number>

export type ProductOptions = IObjectKeys & {
  isMixed?: boolean
  isPlasticless?: boolean
  isNominative?: boolean
  isPreOrder?: boolean
  isBenefitsCalculator?: boolean
  isDigitalization?: boolean
}

export type Solution = 'v1' | 'v3' | 'v4'

export interface Product {
  id: string
  code: ProductCode
  articleCode: ArticleCode | null
  abilities: string | string[]
  isDisabled: boolean
  disabledMessage: string
  options?: ProductOptions
  card: {
    univers: Univers
    title?: string
    leftCorner: {
      text: string
      type: string
    }
    logo: {
      src: string | null
      alt: string | null
    }
    productImage: {
      src: string
      srcset: SrcSet
      alt: string
      fromClick: boolean
    }
    description: string | null
    overlay: {
      ctaId: string
      message: string
      active: boolean
    }
    action: {
      ctaId: string
      message: string
    }
    moreButton?: {
      text: string
      link: string
    }
  }
  solution: Solution | null
  route: string | null
  choices: ProductChoices | null
}

export interface Benefit {
  label: string
  icon: string
}

export interface ProductChoices {
  label: string
  defaultItem: string
  items: ProductChoicesItem[]
}

export interface ProductChoicesItem {
  abilities: string[]
  imgSrc: {
    src: string
    srcset: {
      '2x': string
      '3x': string
    }
    alt: string
    fromClick: boolean
  }
  disabled: boolean
  tooltipMessage: string | null
  label: string
  description: string
  icon: string
  options?: ProductOptions
  solution: Solution
  route: string
  id: string
  benefits?: Benefit[]
}

interface Partners {
  title: string
  list: {
    id: string
    title: string
    images: {
      src: string
      srcset: {
        '2x': string
        '3x': string
      }
      alt: string
      fromClick: boolean
    }
  }[]
}

interface Avantages {
  title: string
  image: {
    src: string
    srcset: {
      '2x': string
      '3x': string
    }
    alt: string
    fromClick: boolean
  }
  univers: string
  action: {
    message: string
    link: string
  }
  blockInfo: {
    id: string
    subtitle: string
    imgSrc: {
      src: string
      alt: string
      fromClick: boolean
    }
    text: string
  }[]
}

export interface UniversJson {
  id: string
  univers: Univers
  discover: string
  image: Image
  banners: Banner[]
  avantages: Avantages
  product: Product[]
  partners: Partners
}
