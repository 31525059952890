import { defineStore } from 'pinia'
import type { AsyncComponent } from 'vue'
import type { ModalState } from './model'

export const useModalStore = defineStore('modal', {
  state: (): ModalState => {
    return {
      push: () => {
        // Stub method
      },
      pop: () => {
        // Stub method
      },
      popAll: () => {
        // Stub method
      },
    }
  },
  actions: {
    initModal(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      push: (id: string, component: AsyncComponent, props: Record<string, any>) => void,
      pop: (id: string) => void,
      popAll: () => void,
    ) {
      this.push = push
      this.pop = pop
      this.popAll = popAll
    },
  },
})
