import type { AsyncComponent, Ref } from 'vue'
import type { IResponse } from 'swrv/dist/types'
import { Block, type StepConfig, type StepWorkflow } from '@/store/cart/model'
import type { PricingData } from '@/types/Price'
import type { CFOrderConfirmationCard } from '@/services/contentful/types/CFOrderConfirmationCard'

export const stepConfig: StepConfig = {
  id: 'confirmation',
  path: 'confirmation',
  name: 'Confirmation',
  blocks: {
    header: Block.Hidden,
  },
}

export interface ConfirmationWorkflow extends StepWorkflow {
  references: Ref<string[]>
  pricings: IResponse<PricingData>[]
  component: AsyncComponent
  cfCard?: CFOrderConfirmationCard
}
