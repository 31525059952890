import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { computed, ref, watch } from 'vue'
import { Result } from '@badrap/result'
import { getError } from '@/utils/vuelidate'
import type { ProductCode } from '@/variables/ProductCode'
import { useStore as useValueOfTitleStore } from '@/store/valueOfTitle'
import type {
  SettingFor,
  UseValueOfTitleWorkflow,
  UseVuelidateValueOfTitle,
} from '@/components/Cart/Module/common/workflow'
import type { ValueOfTitleData } from '@/types/ValueOfTitle'
import useSwrv from '@/utils/useSwrv'
import type { SelectOption } from '@/types/SelectOption'
import { toPercent, toPrice } from '@/utils/price'
import type { ValueOfTitle } from '@/services/valueOfTitle/types'
import apiV4 from '@/services/apiV4'
import { useIsSoge } from '@/composables/useIsSoge'

export const useVuelidateValueOfTitle: (defaultData?: ValueOfTitleData) => UseVuelidateValueOfTitle = (defaultData) => {
  const state = ref<{ value?: SelectOption<ValueOfTitle> }>({
    value: defaultData
      ? {
          value: `${defaultData.value}-${defaultData.employersContribution}`,
          label: `${toPrice(defaultData.value)} - PP: ${toPercent(defaultData.employersContribution)}`,
        }
      : undefined,
  })
  const rules = computed(() => ({
    value: {
      required: helpers.withMessage('simulator.errors.valueOfTitle.required', required),
    },
  }))

  const v$ = useVuelidate(rules, state)

  return { v$ }
}

export const useValueOfTitleWorkflow: (
  productCodes: ProductCode[],
  settingFor: SettingFor,
  defaultData?: ValueOfTitleData,
) => UseValueOfTitleWorkflow = (productCodes, settingFor, defaultData) => () => {
  const valueOfTitleStore = useValueOfTitleStore()
  const config = valueOfTitleStore.getConfig(productCodes[0])
  if (!config) {
    throw new Error('error.valueOfTitleConfigNotfound')
  }

  const { v$ } = useVuelidateValueOfTitle(defaultData)

  const response = useSwrv<SelectOption<ValueOfTitle>[]>(
    () => `valueOfTitle?${productCodes[0]}`,
    async () => {
      const result = await apiV4.valueOfTitle.fetch(productCodes[0], true)

      if (result.isErr) {
        throw result.error
      }

      return result.value.map((item) => ({
        value: `${item.amount}-${item.employersContributionPercentage / 100}`,
        label: `${toPrice(item.amount)} - PP: ${toPercent(item.employersContributionPercentage)}`,
        data: item,
      }))
    },
  )

  watch(
    () => response.data.value,
    (valueOptions) => {
      const option = valueOptions?.find((option) => {
        return option.label === v$.value.value.$model?.label
      })
      if (option) {
        v$.value.value.$model = option
      } else if (v$.value.value.$model == null && valueOptions && valueOptions.length > 0) {
        v$.value.value.$model = valueOptions[0]
      }
    },
  )

  const submit = async () => {
    v$.value.$touch()
    if (v$.value.$invalid) {
      return Result.err(new Error(getError(v$.value.$errors)?.toString()))
    }

    const amount = v$.value.value.$model?.data?.amount ?? 0
    const employersContributionPercentage = v$.value.value.$model?.data?.employersContributionPercentage ?? 0

    for (let index = 1; index < productCodes.length; index++) {
      const productCode = productCodes[index]
      const result = await apiV4.valueOfTitle.create({
        productCode,
        amount,
        employersContributionPercentage,
        employersContributionAmount: v$.value.value.$model?.data?.employersContributionAmount ?? 0,
      })

      if (result?.isErr === true && result.error.message !== 'valueOfTitle.errors.alreadyExists') {
        return Result.err(result.error)
      }
    }

    return Result.ok({
      value: amount,
      employersContribution: employersContributionPercentage,
    })
  }

  const isSoge = useIsSoge()

  return {
    v$,
    config,
    response,
    showMulti: settingFor !== 'input' && !isSoge.value,
    canCreate: !isSoge.value,
    submit,
  }
}
