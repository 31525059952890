export default {
  beneficiaries: {
    title: 'Mes bénéficiaires',
    status: {
      active: 'Actif',
      archived: 'Archivé',
    },
    action: {
      archive: 'Archiver',
      reactivate: 'Réactiver',
    },
    form: {
      edit: 'Modifier le bénéficiaire',
      create: 'Ajouter un bénéficiaire',
    },
    add: {
      label: 'Ajouter bénéficiaire',
      field: 'Saisir un bénéficiaire',
      import: 'Importer un fichier',
    },
    tabs: {
      active: {
        title: 'Bénéficiaires actifs',
        modal: {
          title: 'Archiver mon bénéficiaire',
          description: 'Vous pouvez récupérer votre bénéficiaire dans l’onglet <b>“bénéficiaires archivés”</b>',
          button: 'Archiver',
          success: {
            title: 'Bénéficiaire archivé',
          },
          error: {
            title: "Impossible d'archiver le bénéficiaire",
          },
        },
        importModal: {
          title: 'Importer mes bénéficiaires sur Edenred+',
          description:
            'Si certains de vos bénéficiaires ne font pas partie de votre première commande sur Edenred+, pensez à les archiver.',
          archive: 'Archiver des bénéficiaires',
          button: 'Télécharger ma liste',
          success: {
            title: 'Liste téléchargée',
            description: "Liste téléchargée. Vous pouvez l'importer sur Edenred+.",
          },
        },
      },
      archived: {
        title: 'Bénéficiaires archivés',
        tooltip:
          "Les bénéficiaires n'apparaitront plus dans le parcours de commande mais pourront toujours utiliser leur carte",
        modal: {
          title: 'Réactiver mon bénéficiaire',
          description: 'Ce bénéficiaires sera réintégré à votre liste dans l’onglet <b>“bénéficiaires actifs”</b>',
          button: 'Réactiver',
          success: {
            title: 'Bénéficiaire réactivé',
          },
          error: {
            title: 'Impossible de réactiver le bénéficiaire',
          },
        },
      },
    },
    import: {
      success: 'Votre import a été réalisé avec succès.',
    },
    detail: {
      tabs: {
        orders: {
          title: 'Commandes',
          headers: {
            reference: 'N° de commande',
            type: 'Type de commande',
          },
          type: {
            delivery: 'Expédition de carte',
            loading: 'Chargement',
          },
          quantity: '0 titre | 1 titre | {count} titres',
        },
        cards: {
          title: 'Cartes',
          headers: {
            reference: 'N° de carte',
            endDate: 'Date fin de validité',
          },
        },
        connections: {
          title: 'Rattachements',
          deliveryPoint: 'Point de livraison',
          distributionPoint: 'Point de distribution',
        },
      },
    },
  },
  beneficiary: {
    title: 'Informations personnelles',
    addressTitle: 'Adresse postale',
    updatedDate: 'Date de modification&nbsp;: <b>{date}</b>',
    updatedDateBy: 'Date de modification&nbsp;: <b>{date} par {by}</b>',
    create: {
      description: 'Saisissez une référence de matricule unique pour votre nouveau bénéficiaire&nbsp;:',
      add: 'Ajouter',
    },
    edit: {
      title: 'Modifier',
      subTitle: 'Modifier votre bénéficiaire',
      description: 'Saisissez une référence de matricule unique pour votre bénéficiaire&nbsp;:',
    },
    forms: {
      registrationNumber: {
        label: 'Matricule',
        errors: {
          required: 'Le matricule est requis',
          unique: 'Le matricule que vous avez renseigné doit être unique',
        },
      },
      lastname: {
        label: 'Nom',
        errors: {
          required: 'Le nom est requis',
          maxLength: 'Le nom ne doit pas dépasser {max} caractères',
        },
      },
      firstname: {
        label: 'Prénom',
        errors: {
          required: 'Le prénom est requis',
          maxLength: 'Le prénom ne doit pas dépasser {max} caractères',
        },
      },
      birthdate: {
        label: 'Date de naissance',
        errors: {
          required: 'La date de naissance est requise',
          range: 'Le bénéficiaire doit avoir entre 14 et 130 ans.',
        },
      },
      cellPhone: {
        label: 'Téléphone',
        errors: {
          required: 'Le numero de téléphone est requis',
        },
      },
      email: {
        label: 'Email',
        errors: {
          required: "L'email est requis",
          email: "Mauvais format d'email",
        },
      },
      address1: {
        label: 'Adresse',
        errors: {
          required: "L'adresse est requis",
        },
      },
      address2: {
        label: 'Adresse bis',
      },
      address3: {
        label: 'Complément d’adresse',
      },
      zipCode: {
        label: 'Code postal',
        errors: {
          required: 'Le code postal est requis',
        },
      },
      city: {
        label: 'Ville',
        errors: {
          required: 'La ville est requise',
        },
      },
      country: {
        label: 'Pays',
        errors: {
          required: 'Le pays est requis',
        },
      },
    },
  },
}
