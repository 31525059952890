import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Result } from '@badrap/result'
import apiV3 from '@/services/apiV3'
import type { ClientData } from '@/services/uTR/apis/types'

export const useClientDataStore = defineStore('clientData', () => {
  const clientData = ref<ClientData | null>(null)

  const getClientData = async () => {
    const result = await apiV3.client.getClientData()

    if (result.isOk) {
      clientData.value = result.value

      return Result.ok(clientData.value)
    } else {
      return Result.err(result.error)
    }
  }

  const resetState = () => {
    clientData.value = null
  }

  return {
    clientData,
    getClientData,
    resetState,
  }
})
