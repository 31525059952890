import univers from '@/assets/constantes/univers.json'

export default {
  path: univers.TK.ROOT.path,
  component: () => import('@/views/DefaultRouter/Root/index.vue'),
  meta: univers.TK.ROOT.meta,
  children: [
    {
      path: univers.TK.UNIVERS.path,
      meta: univers.TK.UNIVERS.meta,
      name: univers.TK.UNIVERS.name,
      component: () => import('@/views/Universe/index.vue'),
    },
  ],
}
