import { Result } from '@badrap/result'
import { computed, reactive } from 'vue'
import { helpers, required, between, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useValueOfTitleWorkflow } from '../composables/useValueOfTitleWorkflow'
import { useDeliveryPointWorkflow } from '../composables/useDeliveryPointWorkflow'
import { useI18n } from '@/composables/useI18n'
import { useStore as useCartStore } from '@/store/cart/store'
import { useStore as useTrackingStore } from '@/store/tracking'
import { type AddTRItemWorkflow, moduleTRConfig, type AddTRItemForm } from '@/components/Cart/Module/AddItem/workflow'
import { CartItemType, type CartItem, type CartItemRequest } from '@/services/carts/types'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import apiV4 from '@/services/apiV4'
import { getErrorMessage } from '@/utils/vuelidate'
import { useTrProductItemTracking } from '@/composables/useCartTracking'

const addTRProduct: ModuleBuilder<AddTRItemWorkflow> = {
  config: moduleTRConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow: (record?: { item?: CartItem }) => {
        const cartStore = useCartStore()
        const article = cartStore.article(cart.meta.productCode, cart.meta.articleCode)

        const itemConfigs = cartStore.cartItemConfigs(cart.meta.productCode, cart.meta.articleCode)
        if (itemConfigs == null) {
          throw new Error('cart.errors.cartItemConfig.notFound')
        }

        const i18n = useI18n()
        const itemType = CartItemType.Booklet
        const form: AddTRItemForm = reactive({
          itemType,
          quantity: 10,
          titleQuantity: 10,
        })
        if (record?.item != null) {
          form.itemType = itemConfigs.find((config) => config.type === record.item?.itemType)?.type ?? form.itemType

          form.quantity =
            (form.itemType === CartItemType.Booklet
              ? record.item.packagingQuantity
              : record.item.compositions[0].quantity) ?? 0
          form.titleQuantity =
            (form.itemType === CartItemType.Booklet
              ? record.item.compositions[0].quantity
              : record.item.packagingQuantity) ?? 0
        }
        const itemConfig = computed(() => itemConfigs.find((config) => config.type === form.itemType))

        const bookletItemConfig = itemConfigs.find((config) => config.type === CartItemType.Booklet)
        const boardItemConfig = itemConfigs.find((config) => config.type === CartItemType.Board)

        const rules = computed(() => ({
          itemType: { required },
          quantity: {
            required: helpers.withMessage(i18n.t('cart.modules.addItem.quantity.errors.required').toString(), required),
            between: helpers.withMessage(({ $params }) => {
              return i18n
                .t(`cart.modules.addItem.quantity.errors.${form.itemType}Between`, {
                  min: $params.min,
                  max: $params.max,
                })
                .toString()
            }, between(boardItemConfig?.minQuantity ?? 0, boardItemConfig?.maxQuantity ?? 0)),
          },
          titleQuantity: {
            required: requiredIf(form.itemType === CartItemType.Booklet),
            between: helpers.withMessage(
              ({ $params }) =>
                i18n
                  .t('cart.modules.addItem.quantity.errors.BoardBetween', {
                    min: $params.min,
                    max: $params.max,
                  })
                  .toString(),
              between(bookletItemConfig?.minQuantity ?? 0, bookletItemConfig?.maxQuantity ?? 0),
            ),
          },
        }))

        const v$ = useVuelidate(rules, form)

        return {
          itemConfig,
          itemConfigs,
          v$,
          itemTypeImage: computed(() => {
            if (article == null) {
              return null
            }

            if (form.itemType === CartItemType.Bulk) {
              return article.image
            }

            return article.bookletImage
          }),
          useValueOfTitleWorkflow: useValueOfTitleWorkflow([cart.meta.productCode], 'input'),
          useDeliveryPointWorkflow: useDeliveryPointWorkflow(cartRef, null, {
            for: 'input',
            showType: false,
            productCode: cart.meta.productCode,
            showCardBenefDescription: false,
          }),
          addAction: (submitValueOfTitle, submitDeliveryPoint) =>
            useAction({
              id: 'add',
              name: record?.item ? 'cart.modules.addItem.editAction' : 'cart.modules.addItem.action',
              type: ActionType.Default,
              refresh: true,
              async execute() {
                const resultAmount = await submitValueOfTitle()
                if (resultAmount.isErr) {
                  return Result.err(resultAmount.error)
                }

                const resultDeliveryPoint = await submitDeliveryPoint()
                if (resultDeliveryPoint.isErr) {
                  return Result.err(resultDeliveryPoint.error)
                }
                v$.value.$touch()
                if (v$.value.$invalid) {
                  return Result.err(new Error(getErrorMessage(v$.value.$errors)))
                }

                const itemRequest: CartItemRequest = {
                  itemType: v$.value.itemType.$model,
                  deliveryPointReference: resultDeliveryPoint.value.reference ?? null,
                  distributionPointReference: resultDeliveryPoint.value.distributionPoint?.reference ?? null,
                  packagingQuantity: 0,
                  compositions: [
                    {
                      titleValue: resultAmount.value.value,
                      employersContribution: resultAmount.value.employersContribution / 100,
                      quantity:
                        v$.value.itemType.$model === CartItemType.Booklet
                          ? v$.value.titleQuantity.$model
                          : v$.value.quantity.$model,
                    },
                  ],
                }

                if (v$.value.itemType.$model === CartItemType.Booklet) {
                  itemRequest.packagingQuantity = v$.value.quantity.$model
                } else {
                  itemRequest.packagingQuantity = 1
                }
                let result: Result<CartItem>
                if (record?.item == null) {
                  result = await apiV4.carts.postItem(cart.remote.id, itemRequest)
                } else {
                  result = await apiV4.carts.putItem(cart.remote.id, record.item.id, itemRequest)
                }

                if (result.isErr) {
                  return Result.err(result.error)
                }

                const trackingStore = useTrackingStore()
                const item = useTrProductItemTracking(
                  cart.meta.productCode,
                  cart.meta.articleCode,
                  cart.meta.isNominative,
                  result.value,
                )

                if (item) {
                  trackingStore.trackEvent({
                    id: 'add_to_cart',
                    data: {
                      cart_id: cart.remote.id.toString(),
                      currency: 'EUR',
                      value: result.value.totalAmount / 100,
                      items: [item],
                    },
                  })
                }

                return Result.ok(true)
              },
            }),
        }
      },
    }
  },
}

export default addTRProduct
