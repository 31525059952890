import type { AxiosInstance } from 'axios'
import { Result } from '@badrap/result'
import type { ClientData } from './types'
import type { ApiAxiosError } from '@/services/common/errors'

const baseUrl = '/client'

export default (axiosInstance: AxiosInstance) => {
  const getClientData = async () => {
    try {
      const result = await axiosInstance.get<ClientData>(baseUrl)
      return Result.ok(result.data)
    } catch (error) {
      console.error(error)
      return Result.err(error as ApiAxiosError)
    }
  }

  return {
    getClientData,
  }
}
